<template>
  <div class="lesiurpackages mt-5">
    <div class="container">
      <b-carousel
        v-model="slide"
        :interval="5000"
        :indicators="imageList.length > 1"
        fade
        ref="myCarousel"
        class="banner-carousel"
        :class="{'one-image': imageList.length === 1}"
        style="text-shadow: 1px 1px 2px #333"
        v-if="isOdysseySite || !isAgencyLogin"
      >
        <b-carousel-slide
          v-for="(item, index) in imageList"
          :key="index"
        >
        <template #img>
          <a :href="imageLink[index]">
            <img
              class="d-block img-fluid w-100"
              :src="imageList[index]"
              width="1470"
              height="383"
              :alt="`sliderimage-${index}`"
            />
          </a>
        </template>
        </b-carousel-slide>
      </b-carousel>

      <b-tabs v-model="tabIndex" v-if="device==='desktop'">
        <b-tab v-for="(dealTypeItem, index) in dealTypeList" :key="index" :active="index === 0" :title="dealTypeItem.name">
          <b-card-text>
            <LesiurtabContent
              :dealListItem="dealsList[index]"
              :isMore="isMoreDealData"
              v-if="dealsList && dealsList[index].list.length"
              @showMoreDeal="showMoreDeal"
              :state="showMoreState"/>
            <ContentLoading v-else :type="device === 'mobile' ? 'mobile-deal':'deal'" />
          </b-card-text>
        </b-tab>
      </b-tabs>

      <div class="row my-3 mx-1 mobile-tab-select" v-else>
        <div class="col-6" :class="{'focus': !showDomesticForMobile}" @click="() => {showDomesticForMobile = false;}">
          <b-form-select v-model="tabIndex"
          :options="dealTypeList.map((item, inx) => ({text: item.name, value: inx}))"
          @change="() => {showDomesticForMobile = false;}">
          </b-form-select>
        </div>
        <div class="col-6 mobile-domestic-tab text-center align-self-center" :class="{'focus': showDomesticForMobile}" @click="() => {showDomesticForMobile = true;}">
          {{ $t('home.domestic-tourism') }}
        </div>
      </div>

      <div v-if="!showDomesticForMobile && device==='mobile'">
        <b-card-text v-for="(dealTypeItem, index) in dealTypeList" :key="index">
          <LesiurtabContent
            :dealListItem="dealsList[index]"
            :isMore="isMoreDealData"
            v-if="dealsList && dealsList[index].list.length && index===tabIndex"
            @showMoreDeal="showMoreDeal"
            :state="showMoreState"/>
          <ContentLoading v-else-if="index===tabIndex" :type="device === 'mobile' ? 'mobile-deal':'deal'" />
        </b-card-text>
      </div>

      <b-card-text v-else-if="device==='mobile'">
        <LesiurtabContent
          v-if="domesticTypeTab && domesticTypeTab.list.length"
          :dealListItem="domesticTypeTab"/>
        <ContentLoading v-else-if="showDomesticForMobile" :type="device === 'mobile' ? 'mobile-deal':'deal'" />
      </b-card-text>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText, BCarousel, BCarouselSlide, BFormSelect } from 'bootstrap-vue';
import TemplateLesiurtabs from './TemplateLesiurtabs';

export default {
  name: 'LesiurtabsTheme0',
  mixins: [TemplateLesiurtabs],
  components: {
    LesiurtabContent: () => import('@/components/home/lesiurtabContent/LesiurtabContentTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    BTabs,
    BTab,
    BCardText,
    BCarousel,
    BCarouselSlide,
    BFormSelect,
  },
};
</script>
<style>

.lesiurpackages ul.nav.nav-tabs {
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.lesiurpackages ul.nav.nav-tabs li:first-child {
  border-right: none;
}
.lesiurpackages ul.nav.nav-tabs li {
  padding: 0 0;
  margin: 0 0;
  position: relative;
  border-right: 1px solid #bbb;
  padding-left: 10px;
  text-transform: capitalize;
}
.lesiurpackages ul.nav.nav-tabs li a.active {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li a:hover {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li:first-child a {
  margin: 0 0;
}
.lesiurpackages ul.nav.nav-tabs li a {
  border: none;
  padding: 8px 18px;
  margin: 0 10px 0 0;
  background: transparent;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}
.lesiurpackages ul.nav.nav-tabs li a.active:after {
  display: block;
  background: #005fa8;
}
.lesiurpackages ul.nav.nav-tabs li a:after {
  display: none;
  content: " ";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #005fa8;
  left: -4px;
  bottom: -4px;
  margin: 0 50%;
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.lesiurpackages .tab-content {
  border: none;
}
.lesiurtabFilter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 479px) {
  .lesiurpackages .carousel-indicators {
    margin-bottom: -0.25rem;
  }
  .lesiurpackages .carousel-indicators li{
    width: 0.65rem;
    height: 0.65rem;
  }
  .lesiurpackages_head h1 {
    font-size: 18px;
  }
  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }
  .lesiurpackages ul.nav.nav-tabs li a {
    padding: 6px 8px;
    font-size: 14px;
    margin-right: 5px;
  }
  .lesiurpackages ul.nav.nav-tabs {
    padding-right: 0px;
  }
  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
  .lesiurpackages ul.nav.nav-tabs li {
    padding-left: 5px;
    max-width: 20rem;
    white-space: nowrap;
  }
  /* .tab-title{
    width: min-content;
  } */
  .lesiurpackages ul.nav.nav-tabs li a.active:after{
    display: none;
  }
  .tab-title {
    width: fit-content;
  }
}
</style>
